<template>
  <div v-if="templateInput" class="page p-0">
    <h2 class="page-title">
      <span class="fa fa-graduation-cap"></span>
      {{ pageTitle }}
    </h2>
    <p class="mb-4" style="color: #17312d">
      {{ pageDescription }}
    </p>

    <div style="display: flex; justify-content: center">
      <Form
        v-if="nodes"
        class="left-col"
        ref="pageForm"
        :id="formId"
        :lessonInstanceId="lessonId"
        :elements="nodes"
        :form-data="formData"
        :isPassFail="isPassFail"
        style="max-width: 800px"
      />
    </div>
  </div>
</template>

<script>
import { ServiceBus } from "@/leapform/LeapFormServiceBus";

import Form from "@/components/Form";

export default {
  name: "LessonPage",
  components: {
    Form,
  },
  props: {
    lessonId: {
      type: String,
      required: true,
    },
    pageKey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      formId: null,
      isPassFail: false,
      loading: true,
      page: {
        title: "",
        description: "",
      },
      formData: {},
    };
  },
  computed: {
    templateInput() {
      return this.page.templateInput;
    },
    nodes() {
      return this.page.nodes;
    },
    pageTitle() {
      return this.page.templateInput["rekom.campusonline:title"];
    },
    pageDescription() {
      return this.page.templateInput["rekom.campusonline:description"];
    },
  },
  methods: {
    async loadRequiredData() {
      this.loading = true;

      await this.$store.dispatch("lessons/ensureLessonInstance", {
        lessonInstanceId: this.lessonId,
      });

      const loadedLessonInstance = this.$store.getters[
        "lessons/lessonInstance"
      ](this.lessonId);

      console.log("Loaded Lesson Instance:", loadedLessonInstance);

      let loadedPage = null;
      for (const category in loadedLessonInstance.lesson.compiledLesson
        .categories) {
        const loadedCategoryPage =
          loadedLessonInstance.lesson.compiledLesson.categories[
            category
          ].pages.find((page) => page.slug === this.pageKey);
        if (loadedCategoryPage) {
          loadedPage = loadedCategoryPage;
        }
      }

      this.isPassFail = loadedLessonInstance.lesson.isPassFail;
      this.page = JSON.parse(JSON.stringify(loadedPage));

      console.log("Loaded Page:", this.page);

      this.formId = this.page.identifier;

      if (loadedLessonInstance.lessonInstance.data) {
        await this.$store.dispatch("forms/cacheForm", {
          formId: this.formId,
          data: loadedLessonInstance.lessonInstance.data,
        });
      }

      ServiceBus.instance().on("end-event", async () => {
        // console.log("GOT END EVENT:", event);

        const courseId = this.$route.params.courseId;

        await this.$store.dispatch("lessons/complete", {
          courseId: courseId,
          lessonInstanceId: this.lessonId,
        });

        this.$router.push({
          name: "course",
          params: { courseId: courseId },
          query: { courseId: courseId },
        });
      });

      ServiceBus.instance().on("form:" + this.formId, async (/*event*/) => {
        if (this.$refs.pageForm === null) {
          return;
        }

        const formData = this.$store.getters["forms/form"](
          this.page.identifier
        );

        const command = {
          courseId: this.$route.params.courseId,
          lessonInstanceId: this.lessonId,
          pageId: this.page.identifier,
          data: formData,
        };

        await this.$store.dispatch("lessons/savePage", command);
      });

      this.loading = false;
    },
  },
  mounted() {
    this.loadRequiredData();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h2.page-title {
  font-weight: 800;
  color: #17312d;
}

.p-0 {
  padding: 0;
}

.left-col {
  flex: 1;
}

.right-col {
  width: 350px;
  margin-left: 15px;
}
</style>
