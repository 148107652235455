<template>
  <div v-if="loading" class="loading-container mx-auto mt-16">
    <Loading text="Loading course" />
  </div>
  <div class="grid grid-cols-1 sm:grid-cols-3 xl:grid-cols-4 md:h-full relative" v-else>
    <!-- Left side -->
    <div class="bg-white shadow p-3 h-full relative">
      <div>
        <div class="flex justify-between">
          <button @click="goBack" class="btn btn-info">Back</button>
          <div>
            <span
              v-if="meta"
              class="badge bg-primary p-2 text-dark"
              :style="meta == 'In progress' ? ['background-color: #3c78d8 !important; color: white !important'] : []"
              >{{ meta }}</span
            >
          </div>
        </div>

        <h2 class="text-2xl mt-3 mb-3 font-bold tracking-tight text-gray-900 flex items-center gap-2">
          <span class="fa fa-book"></span>
          {{ course.title }}
        </h2>

        <p>
          {{ course.description }}
        </p>

        <div class="my-2">
          <ProgressBar :value="course.completedPercentage.toFixed(0)"></ProgressBar>
        </div>

        <div class="flex gap-3 mt-3">
          <div class="flex items-center gap-1">
            <span class="fa-solid fa-clock"></span>
            <span class="whitespace-nowrap"> {{ course.estimatedTime }} min </span>
          </div>
          <div class="flex items-center gap-1">
            <span class="fa fa-graduation-cap"></span>
            <span class="whitespace-nowrap"> {{ course.children.length }} lessons </span>
          </div>
        </div>
      </div>

      <div class="flex justify-center mt-6">
        <button v-if="!course.hasEverTaken" @click="enrollCourse" class="btn btn-secondary">Enroll course</button>
        <button v-else-if="course.isCompleted" @click="enrollCourse" class="btn btn-secondary">Retake course</button>
      </div>
    </div>

    <!-- Right side -->
    <div class="p-4 col-span-2 xl:col-span-3">
      <h3 class="text-2xl mb-3 font-bold tracking-tight text-gray-900">
        <span class="fa fa-graduation-cap"></span>
        Course lessons
      </h3>

      <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
        <LessonCard
          v-for="item in course.children"
          :key="item.id"
          :title="item.title"
          :status="item.status"
          :cooldownExpireDate="item.cooldownExpireDate"
          :lessonInstanceId="item.lessonInstanceId"
          :description="item.description"
          :imageUrl="item.imageUrl"
          @action="enroll(item)"
          actionText="Start"
          icon="fa-play-circle"
        />
      </div>
    </div>
  </div>
</template>

<script>
import LessonCard from "../common/LessonCard.vue";
import ProgressBar from "primevue/progressbar";
export default {
  name: "MandatoryCoursesView",
  components: {
    LessonCard,
    ProgressBar
  },
  data() {
    return {
      course: null,
      loading: true
    };
  },
  async mounted() {
    await this.loadCourse();
  },
  computed: {
    meta() {
      if (this.course.shouldRetakeNow) return "Must revisit";
      if (this.course.hasEverTaken && !this.course.isCompleted) return "In progress";
      if (!this.course.hasEverTaken) return "";
      return "Completed";
    }
  },
  methods: {
    goBack() {
      this.$router.push({
        name: "my-learning"
      });
    },
    async loadCourse() {
      this.loading = true;
      this.course = await this.$store.dispatch("courses/getCourseViewModel", this.$route.params.courseId);
      this.loading = false;
    },
    async enrollCourse() {
      console.log("Enroll course", this.course);
      const result = await this.$store.dispatch("courses/enrollCourse", this.course.id);
      await this.loadCourse();
      console.log("Enroll result:", result);
    },
    async enroll(selectedLesson) {
      console.log("Enroll lesson", selectedLesson);
      // Course ID is last item in this.activeFolderPath
      const courseId = this.course.id;
      if (selectedLesson.status === "Ready" || selectedLesson.status === "Completed") {
        console.log("Start new lesson", selectedLesson);
        try {
          const result = await this.$store.dispatch("lessons/enroll", {
            courseId: courseId,
            lessonId: selectedLesson.id
          });
          console.log("Enroll result:", result);

          this.$router.push({
            name: "load-preview-lesson-page",
            params: { lessonId: result.lessonInstanceId },
            query: { courseId: courseId } // Add courseId to the URL query
          });
        } catch (err) {
          // toast.error("Could not start Campus: " + err);
        }
      } else if (selectedLesson.status === "InProgress") {
        try {
          this.$router.push({
            name: "load-preview-lesson-page",
            params: { lessonId: selectedLesson.lessonInstanceId },
            query: { courseId: courseId } // Add courseId to the URL query
          });
        } catch (err) {
          // toast.error("Could not open Campus: " + err);
        }
      }
    }
  }
};
</script>