<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light static-top">
    <div class="container-fluid">
      <a
        href="/my-learning"
        class="navbar-brand d-none d-lg-block"
        style="width: 45px"
      >
        <img src="@/assets/logo.png" alt="Logo" class="navbar-logo" />
      </a>
      <div class="navbar-brand d-none d-xl-block w-full">
        <h3 style="color: #f4e8d3">REKOM Learning Online</h3>
      </div>
      <button
        class="navbar-toggler"
        type="button"
        style="color: #e6bd71"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <nav-item-profile :user="user" class="d-block d-lg-none" />
      <div
        class="collapse navbar-collapse justify-content-end"
        id="navbarSupportedContent"
      >
        <ul class="navbar-nav px-3 py-2 p-mb-0">
          <!-- <li class="nav-item link">
            <form class="form-inline my-2 my-lg-0">
              <div class="input-group search-input-group">
                <div class="input-group-prepend">
                  <span
                    class="input-group-text bg-white border-right-0"
                    id="search-addon"
                  >
                    <i style="color: #e6bd71" class="fa fa-search"></i>
                  </span>
                </div>
                <input
                  class="form-control border-left-0"
                  type="search"
                  placeholder="Search for course"
                  aria-label="Search for course"
                  aria-describedby="search-addon"
                  style="height: calc(1.3em + 0.75rem + 2px)"
                />
              </div>
            </form>
          </li> -->
          <li
            class="nav-item link"
            v-for="option in config.options"
            :key="option.title"
          >
            <router-link :to="option.slug" :class="option.class"
              ><span
                class="fa"
                style="font-size: 1.6rem"
                :class="option.icon"
              ></span>
              {{ option.title }}</router-link
            >
          </li>
        </ul>
      </div>
      <nav-item-profile :user="user" class="d-none d-lg-block" />
    </div>
  </nav>
</template>

<script>
import { ref } from "vue";
/*import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
*/

import { authPlugin as $auth } from "@/auth";
import NavItemProfile from "./NavItemProfile.vue";

export default {
  name: "NavBar",
  components: {
    NavItemProfile,
  },
  props: {},
  setup() {
    const user = ref($auth.user);

    const config = ref({
      options: [
        {
          title: "My Learning",
          icon: "fa-university",
          slug: "/my-learning",
        },
        {
          title: "Certificates",
          icon: "fa-solid fa-award",
          slug: "/certificates",
        },
      ],
    });

    return {
      user,
      config,
      // activePage: computed(() => store.getters['page/activePage'])
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.navbar-logo {
  width: 45px;
}
img {
  max-width: fit-content;
}
.form-control:focus,
.form-control:active {
  border: none !important;
}
.search-input-group {
  border-radius: 25px;
}

.search-input-group .input-group-text,
.search-input-group .form-control {
}

.search-input-group .input-group-text {
  border-radius: 25px 0 0 25px;
}

.search-input-group .form-control {
  border-radius: 0 25px 25px 0;
}
.navbar {
  background-image: url("../assets/texture.png");
  background-repeat: repeat;
  margin-bottom: 0px;
}

.nav-item {
  line-height: 52px;
}

.nav-item.link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 50px;
}
.nav-item.link a {
  color: #fff;
  font-size: clamp(12, 1.2vw, 18px);
  font-weight: 500;
}

.nav-item.link a.router-link-active {
  color: #e6bd71;
  position: relative;
}

.nav-item.link a.router-link-active::after {
  content: "";
  position: absolute;
  left: 0px;
  bottom: -24px;
  width: 100%;
  height: 8px;
  background-color: #e6bd71;
}

@media only screen and (max-width: 992px) {
  .nav-item.link a.router-link-active::after {
    display: none;
  }
}
</style>
