<template>
  <div class="page">
    <!--<select-position-popup />-->
    <div class="row">
      <div class="col-md-auto col-12 d-flex align-items-center">
        <h2 class="page-title">My learning</h2>
      </div>
      <div class="col-md col-12" v-if="!showMandatoryCoursesView">
        <form class="form-inline mb-3 mb-md-0 mt-md-0 w-100">
          <div class="input-group search-input-group">
            <div class="input-group-prepend">
              <span class="input-group-text bg-white border-right-0" id="search-addon">
                <i style="color: #e6bd71" class="fa fa-search"></i>
              </span>
            </div>
            <input
              class="form-control border-left-0 flex-grow-1"
              type="search"
              placeholder="Search for course"
              aria-label="Search for course"
              aria-describedby="search-addon"
              style="height: calc(1.3em + 0.75rem + 2px)"
              v-model="searchQuery"
              @input="search"
            />
          </div>
        </form>
      </div>
    </div>

    <nav class="flex items-center gap-2" aria-label="Breadcrumb">
      <ol v-if="!showMandatoryCoursesView" role="list" class="flex space-x-4 rounded-md px-6 shadow texture-background">
        <li class="flex">
          <div class="flex items-center">
            <a href="#" @click.prevent="goToFolder(0)" class="text-white hover:text-gray-500">
              <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
              <span class="sr-only">Home</span>
            </a>
          </div>
        </li>
        <li class="flex breadcrumb-item" v-for="(page, index) in breadcrumb" :key="page.name" :class="`breadcrumb-item-${index}`">
          <div class="flex items-center">
            <svg
              class="h-full w-6 flex-shrink-0 text-gray-200"
              viewBox="0 0 24 44"
              preserveAspectRatio="none"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
            </svg>
            <a href="#" class="ml-4 text-sm font-medium text-white" @click.prevent="goToFolder(index)">{{ page.name }}</a>
          </div>
        </li>
      </ol>
    </nav>
    <div class="flex items-center gap-2">
      <InputSwitch v-model="showMandatoryCoursesView" aria-labelledby="basic" class="ml-2 mb-2" />
      <label for="basic" class="p-mr-2">Only show mandatory courses</label>
    </div>

    <MandatoryCoursesView v-if="showMandatoryCoursesView" :mandatoryCourses="campusCatalogue.mandatoryCourses" class="mt-6" />
    <div v-else>
      <div v-if="activeFolder != null" class="d-md-none">
        <!-- <h5 class="page-title">{{ activeFolder.title }}</h5> -->
        <button @click="goBack" class="btn btn-info">Back</button>
      </div>

      <div>
        <div v-if="loading" class="loading-container mx-auto">
          <Loading text="Loading your courses" />
        </div>

        <div class="mt-3" v-else>
          <div v-if="campusCatalogue.folders.length === 0" style="text-align: center" class="mt-3">
            <img src="@/assets/icons/empty-box.png" alt="" style="opacity: 0.3; width: 178px; height: 178px" />
            <h3 style="color: #fff">There are no results.</h3>
          </div>

          <!-- Search start -->
          <template v-if="searchQuery">
            <div class="row">
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" v-for="item in filteredItems" :key="item.id">
                <CourseCard v-if="item.type === 'course'" :course="item" @action="onClickCourse(item)" />
              </div>
            </div>
          </template>
          <!-- Search end -->
          <div v-else>
            <div class="row" v-if="activeFolder == null">
              <template v-for="(item, index) in campusCatalogue.folders" :key="index">
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" v-if="countCourses(item) > 0">
                  <!-- Folder -->
                  <FolderCard
                    v-if="item.type === 'folder'"
                    :title="item.title"
                    :completed="item.allCompleted"
                    :meta="countCourses(item) + ' courses'"
                    :description="item.description"
                    :imageUrl="item.imageUrl"
                    @action="openFolder(item)"
                    actionText="Open"
                    icon="fa-folder"
                  />
                </div>
              </template>
            </div>
            <!-- Course in folder (we've selected a folder at this point) -->
            <div class="row" v-else>
              <template v-for="(group, groupIndex) in groupedActiveFolderChildren()" :key="groupIndex">
                <!-- <hr
                v-if="group.subTopic"
                class="w-100 mt-4"
              /> -->
                <hr
                  v-if="group.subTopic && groupIndex > 0"
                  class="h-px my-8 border-5"
                  style="background-color: #122e2b; opacity: 1 !important; border-radius: 3px"
                />
                <h2 v-if="group.subTopic" class="w-100">
                  {{ group.subTopic }}
                </h2>
                <template v-for="item in group.items" :key="item.id">
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" v-if="item.type !== 'folder' || countCourses(item) > 0">
                    <FolderCard
                      v-if="item.type === 'folder'"
                      :title="item.title"
                      :completed="item.allCompleted"
                      :description="item.description"
                      :imageUrl="item.imageUrl"
                      :meta="countCourses(item) + ' courses'"
                      @action="openFolder(item)"
                      actionText="Open"
                      icon="fa-folder"
                    />
                    <CourseCard v-else-if="item.type === 'course'" :course="item" @action="onClickCourse(item)" />
                  </div>
                </template>

                <!-- Add horizontal line after the last group of courses with subtopics -->
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CourseCard from "../common/CourseCard.vue";
import FolderCard from "../common/FolderCard.vue";
import { HomeIcon } from "@heroicons/vue/20/solid";
import { mapGetters } from "vuex";
// import SelectButton from "primevue/selectbutton";
import InputSwitch from "primevue/inputswitch";
import MandatoryCoursesView from "./components/MandatoryCoursesView.vue";

export default {
  components: {
    FolderCard,
    CourseCard,
    HomeIcon,
    // SelectButton,
    InputSwitch,
    MandatoryCoursesView
  },
  data() {
    return {
      loading: true,
      activeFolderPath: [],
      searchQuery: "",
      showMandatoryCoursesView: false
    };
  },
  computed: {
    filteredItems() {
      if (!this.searchQuery) {
        return [];
      }

      const searchResults = [];
      const searchCourses = children => {
        for (const child of children) {
          if (child.type === "course") {
            const titleMatch = child.title.toLowerCase().includes(this.searchQuery.toLowerCase());

            const tagMatch = child.searchTags.some(tag => tag.toLowerCase().includes(this.searchQuery.toLowerCase()));

            if (titleMatch || tagMatch) {
              searchResults.push(child);
            }
          } else if (child.type === "folder") {
            searchCourses(child.children);
          }
        }
      };

      searchCourses(this.campusCatalogue.folders);

      return searchResults;
    },
    ...mapGetters({
      campusCatalogue: "campusCatalogue/catalogue"
    }),
    breadcrumb() {
      return [
        { name: "All courses", href: "#", current: false },
        ...this.activeFolderPath.map((folder, index) => ({
          name: folder.title,
          href: "#",
          current: index === this.activeFolderPath.length - 1
        }))
      ];
    },
    activeFolder() {
      if (this.activeFolderPath.length === 0) {
        return null;
      }
      return this.activeFolderPath[this.activeFolderPath.length - 1];
    },
    itemsInActiveFolder() {
      return this.getItemsInFolder(this.activeFolder);
    },
    showRevisitButton() {
      return (
        this.activeFolder &&
        this.activeFolder.type === "course" &&
        this.activeFolder.shouldRevisit &&
        this.activeFolder.completedPercentage === 100
      );
    }
  },
  methods: {
    async onClickCourse(course) {
      console.log("COURSE", course, course.id);
      this.$router.push({
        name: "course",
        params: { courseId: course.id }
      });
    },
    search() {
      if (!this.searchQuery) {
        return;
      }

      this.activeFolderPath = []; // Clear the active folder path to display search results
    },
    findCourseById(courseId) {
      let foundCourse = null;

      const traverseChildren = children => {
        for (const child of children) {
          if (foundCourse) {
            break;
          }
          if (child.type === "course" && child.id === courseId) {
            foundCourse = child;
            break;
          } else if (child.type === "folder") {
            traverseChildren(child.children);
          }
        }
      };

      traverseChildren(this.campusCatalogue.folders);

      return foundCourse;
    },
    async loadRequiredData() {
      this.loading = true;
      try {
        await this.$store.dispatch("campusCatalogue/getCatalogue");
        // console.log("Catalogue loaded", this.campusCatalogue);
      } catch (err) {
        console.error("Error could not load catalogue");
      }

      const currentCourseId = this.$route.query.courseId;
      if (currentCourseId) {
        const course = this.findCourseById(currentCourseId);

        if (course) {
          this.activeFolderPath.push(course);
        }
      }

      this.loading = false;
    },
    goToFolder(index) {
      console.log("goToFolder", index);
      if (index === 0) {
        this.activeFolderPath = [];
      } else {
        this.activeFolderPath = this.activeFolderPath.slice(0, index);
      }
    },
    countCourses(folder) {
      if (!folder) {
        return 0;
      }

      let courseCount = 0;

      const traverseChildren = children => {
        for (const child of children) {
          if (child.type === "course") {
            courseCount++;
          } else if (child.type === "folder") {
            traverseChildren(child.children);
          }
        }
      };

      traverseChildren(folder.children);

      return courseCount;
    },
    groupedActiveFolderChildren() {
      if (!this.activeFolder) {
        return [];
      }
      const grouped = [];
      const subTopicGroups = {};
      const noSubTopicItems = [];

      this.activeFolder.children.forEach(item => {
        if (item.subTopic) {
          if (!subTopicGroups[item.subTopic]) {
            subTopicGroups[item.subTopic] = [];
          }
          subTopicGroups[item.subTopic].push(item);
        } else {
          noSubTopicItems.push(item);
        }
      });

      for (const subTopic in subTopicGroups) {
        grouped.push({
          subTopic: subTopic,
          items: subTopicGroups[subTopic]
        });
      }

      if (noSubTopicItems.length > 0) {
        grouped.push({
          subTopic: null,
          items: noSubTopicItems
        });
      }

      return grouped.reverse();
    },
    lastGroupWithSubTopicIndex() {
      const groups = this.groupedActiveFolderChildren();
      let lastIndex = -1;

      groups.forEach((group, index) => {
        if (group.subTopic) {
          lastIndex = index;
        }
      });

      return lastIndex;
    },
    sortedActiveFolderChildren() {
      if (!this.activeFolder) {
        return [];
      }
      return this.activeFolder.children.sort((a, b) => {
        if (a.subTopic && !b.subTopic) {
          return -1;
        } else if (!a.subTopic && b.subTopic) {
          return 1;
        } else {
          return 0;
        }
      });
    },

    getItemsInFolder(folder) {
      if (!folder) {
        return [];
      }

      const items = [];

      const traverseChildren = children => {
        for (const child of children) {
          if (child.type === "course") {
            items.push(child);
          } else if (child.type === "folder") {
            items.push(child);
            traverseChildren(child.children);
          }
        }
      };

      traverseChildren(folder.children);

      return items;
    },
    openFolder(folder) {
      this.searchQuery = "";
      this.activeFolderPath.push(folder);
    },

    goBack() {
      this.showRevisitButton = false;
      this.activeFolderPath.pop();
    },

    getCoursesInFolder(folder) {
      if (!folder) {
        return [];
      }

      const courses = [];

      const traverseChildren = children => {
        for (const child of children) {
          if (child.type === "course") {
            courses.push(child);
          } else if (child.type === "folder") {
            traverseChildren(child.children);
          }
        }
      };

      traverseChildren(folder.children);

      return courses;
    }
  },
  async mounted() {
    await this.loadRequiredData();
  }
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.form-control:focus,
.form-control:active {
  border: 1px solid #ced4da;
}
.search-input-group {
  border-radius: 25px;
}

.search-input-group .input-group-text,
.search-input-group .form-control {
}

.search-input-group .input-group-text {
  border-radius: 25px 0 0 25px;
}

.search-input-group .form-control {
  border-radius: 0 25px 25px 0;
}

/* Add media query for small devices */
@media (max-width: 768px) {
  .breadcrumb-item {
    display: none;
  }

  .breadcrumb-item:last-child {
    display: flex;
  }
}
.texture-background {
  background-image: url("@/assets/texture.png");
  background-repeat: repeat;
}
/* Your previous styles */
</style>
