<template>
  <div class="flex flex-col justify-center items-center">
    <div>
      <h4 class="mt-5 text-center">{{ endMessage }}</h4>
    </div>
    <Button @click="onClick" :loading="loading" severity="success" type="button" icon="fa fa-check" :label="endButtonTitle" />
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import Button from "primevue/button";

// import { ServiceBus } from "@/leapform/LeapFormServiceBus";

export default {
  name: "EndEvent",
  components: { Button },
  props: ["id", "namespace", "json", "outgoing", "lessonInstanceId"],
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const loading = ref(false);
    const selectedValue = ref("");
    const endMessage = ref("Tillykke. Du har gennemført kurset'en.");
    const endButtonTitle = ref("Click to complete");

    const loadedLessonInstance = store.getters["lessons/lessonInstance"](props.lessonInstanceId);

    console.log("ENDEVENT: Loaded Lesson Instance:", loadedLessonInstance);

    if (props.json) {
      const element = JSON.parse(props.json);
      if (element && element.endMessage) {
        endMessage.value = element.endMessage;
      }

      if (element && element.endButtonTitle) {
        endButtonTitle.value = element.endButtonTitle;
      }
    }

    const refreshValue = () => {
      const loadedValue = store.getters["forms/get"](props.namespace + "." + props.id);

      selectedValue.value = loadedValue || "";
    };

    const onActivate = () => {
      refreshValue();

      console.log("END EVENT - SELECTED VALUE:", selectedValue.value);

      if (selectedValue.value !== "") {
        return;
      }
    };

    const onClick = async () => {
      console.log("EndEvent Button click:", props.id);

      // ServiceBus.instance().emit("end-event", {
      //   event: "end-event",
      //   payload: {
      //     namespace: props.namespace,
      //     id: props.id,
      //   },
      // });

      const courseId = route.params.courseId;
      const lessonInstanceId = route.params.lessonId;

      loading.value = true;
      await store.dispatch("lessons/complete", {
        courseId: courseId,
        lessonInstanceId: lessonInstanceId
      });

      router.push({
        name: "course",
        params: { courseId: courseId },
        query: { courseId: courseId }
      });
    };

    onMounted(() => {
      console.log("EndEvent: onMounted");
      console.log("EndEvent: props", props);
      onActivate();
    });

    return {
      endMessage,
      endButtonTitle,
      selectedValue,
      loading,

      refreshValue,
      onClick
    };
  },
  created() {
    this.unsubscribe = this.$store.subscribe(mutation => {
      if (mutation.type === "forms/fieldCached") {
        this.refreshValue();
      }
    });
  },
  mounted() {
    this.refreshValue();
  },
  beforeUnmount() {
    this.unsubscribe();
  }
};
</script>

<style lang="scss">
</style>
