import * as apiEnrollLesson from '@/api/lessonInstances/enrollLesson'
import * as apiRetakeFailedLesson from '@/api/lessonInstances/retakeFailedLesson'
import * as apiGetLessonInstances from '@/api/lessonInstances/getLessonInstances'
import * as apiGetLessonInstance from '@/api/lessonInstances/getLessonInstance'
import * as apiSaveLessonPage from '@/api/lessonInstances/saveLessonPage'
import * as apiCompleteLesson from '@/api/lessonInstances/completeLesson'
import * as apiGetLessonStats from '@/api/lessonInstances/getLessonStats'

import _ from "lodash";

export default {
    namespaced: true,
    state: () => ({
        loading: false,
        lessonInstanceListItems: [],
        lessonInstances: {}
    }),
    mutations: {
        gotLessonInstanceListItems(state, lessonInstances) {
            state.lessonInstanceListItems = lessonInstances
        },

        gotLessonInstance(state, lessonInstance) {
            //console.log('Got lesson instance:', lessonInstance)
            state.lessonInstances[lessonInstance.lessonInstance.id] = lessonInstance
        },

        /*
        pageSaved(state, payload) {
            state.lessonInstances[payload.lessonInstanceId].data = payload.data

            console.log('Stored lesson instance:', state.lessonInstances[payload.lessonInstanceId])
        },*/

        lessonCompleted(state, lessonId) {
            state.lessonInstances[lessonId].completed = true
            state.lessonInstances[lessonId].completedOn = Date()
        },

        isLoading(state, loading) {
            state.loading = loading
        }
    },
    actions: {
        async getLessonInstanceListItems({ commit }) {
            commit('isLoading', true)

            //console.log('Getting Lesson Instance List Items');

            const result = await apiGetLessonInstances.get();

            if (result) {
                commit('gotLessonInstanceListItems', result)
            }

            commit('isLoading', false)
        },

        async getLessonInstance({ commit }, { courseId, lessonInstanceId }) {
            commit('isLoading', true)

            const result = await apiGetLessonInstance.get(courseId, lessonInstanceId)
            if (result) {
                console.log('Got result:', result)

                /*
                if (!result.lessonInstance.completed || result.lessonInstance.isReset) {
                    // Reset data when Lesson Instance is reloaded
                    console.log('Resetting Lesson Instance data')
                    result.lessonInstance.data = {}
                }*/

                commit('gotLessonInstance', result)
            }

            commit('isLoading', false)
        },

        async enroll({ commit }, { lessonId, courseId }) {
            commit('isLoading', true)

            //console.log('Enrolling lesson...')

            const result = await apiEnrollLesson.post({ lessonId, courseId })

            commit('isLoading', false)

            return result
        },

        async retakeFailed({ commit }, { lessonId, courseId }) {
            commit('isLoading', true)

            console.log('Re-enrolling failed lesson...')

            const result = await apiRetakeFailedLesson.post({ lessonId, courseId })

            commit('isLoading', false)

            return result
        },

        async complete({ commit }, { lessonInstanceId, courseId }) {
            commit('isLoading', true)

            // console.log('Completing Lesson...')

            console.log('Completing Lesson:', lessonInstanceId, courseId);

            await apiCompleteLesson.put(courseId, lessonInstanceId)
            commit('lessonCompleted', lessonInstanceId)

            commit('isLoading', false)
        },

        async ensureLessonInstanceListItems({ commit, dispatch/*, getters*/ }) {
            commit('isLoading', true)

            //if (getters.lessonInstanceListItems.length === 0) {
            await dispatch('getLessonInstanceListItems')
            //}

            commit('isLoading', false)
        },

        async getLessonStats({ commit }, { courseId, lessonInstanceId }) {
            commit('isLoading', true)

            console.log("LOADING WITH STATS", courseId, lessonInstanceId);

            const result = await apiGetLessonStats.get(courseId, lessonInstanceId)

            commit('isLoading', false)
            return result
        },

        async ensureLessonInstance({ commit, dispatch, getters }, { lessonInstanceId }) {
            commit('isLoading', true)

            if (!getters.lessonInstance(lessonInstanceId)) {
                //console.log('Lesson Instance not cached. Getting from API.')
                await dispatch('getLessonInstance', { lessonInstanceId: lessonInstanceId })
            }

            commit('isLoading', false)
        },
        async savePage({ commit }, { courseId, lessonInstanceId, pageId, data }) {
            commit('isLoading', true)

            const trimmedData = _.pickBy(data, v => v !== undefined)

            //const trimmedData = Object.keys(data).forEach(key => data[key] === undefined && delete data[key])
            const payload = {
                pageId: pageId,
                data: trimmedData
            };

            console.log('Saving Lesson page:', courseId, lessonInstanceId, payload)

            await apiSaveLessonPage.put(courseId, lessonInstanceId, payload)
            /*commit('pageSaved', {
                lessonInstanceId: lessonInstanceId, 
                pageId: pageId, 
                data: data
            })*/

            commit('isLoading', false)
        }



    },
    getters: {
        lessonInstanceListItems: (state) => state.lessonInstanceListItems,

        lessonInstance: (state) => (lessonInstanceId) => {
            return state.lessonInstances[lessonInstanceId]
        },
    }
};