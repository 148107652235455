<template>
  <div class="card position-relative h-100">
    <img
      class="card-img-top-rounded"
      :src="imageOrDefault"
      alt="Campus image"
    />

    <!-- v-if="!course.isCompleted" -->
    <ProgressBar
      :value="course.completedPercentage.toFixed(0)"
      class="w-full"
      style="height: 12px; border-radius: 0px; font-size: 0.8rem"
      :pt="{
        value: {
          style: { background: progressBarColor },
        },
      }"
    ></ProgressBar>

    <div class="position-absolute w-100">
      <div class="flex justify-between m-3">
        <div>
          <span
            v-if="completed"
            class="badge status-badge bg-green-500 px-4 text-white"
          >
            <i class="fas fa-check text-lg" aria-hidden="true"></i>
          </span>
          <!--       :style="
              meta == 'In progress'
                ? [
                    'background-color: #3c78d8 !important; color: white !important',
                  ]
                : []
            " -->
          <span v-else-if="meta" class="badge bg-primary p-2 text-dark">{{
            meta
          }}</span>
        </div>
        <div>
          <button
            class="btn-sm text-white"
            :class="actionButtonColor"
            @click="onClickAction"
          >
            <i
              class="fa fa-book"
              aria-hidden="true"
              style="font-size: 0.9rem"
            ></i>
            {{ actionText }}
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <h5 class="card-title">{{ course.title }}</h5>
      <div>
        <p class="card-text">
          {{ truncatedDescription }}
        </p>
        <span
          v-if="course.description.split(' ').length > maxWords"
          class="cursor-pointer"
          @click="toggleShowMore"
        >
          {{ showMore ? "Show Less" : "Show More" }}
        </span>
      </div>

      <div class="flex gap-3 mt-2">
        <div class="flex items-center gap-1">
          <span class="fa-solid fa-clock"></span>
          <span class="whitespace-nowrap">
            {{ course.estimatedTime }} min
          </span>
        </div>
        <div class="flex items-center gap-1">
          <span class="fa fa-graduation-cap"></span>
          <span class="whitespace-nowrap">
            {{ course.children.length }} lessons
          </span>
        </div>
      </div>
      <div class="flex flex-col w-full mt-2">
        <span class="text-red-600" v-if="course.shouldRetakeNow">
          <!-- <strong
              >You should revisit this course ({{
                $filters.formatDate(course.lastCompletionDate)
              }})</strong> -->
          <strong>You should revisit this course now!</strong>
        </span>
        <span class="text-yellow-600" v-else-if="course.shouldRetakeSoon">
          <!-- <strong
              >You should consider revisiting this course ({{
                $filters.formatDate(course.lastCompletionDate)
              }})</strong> -->
          <strong>You should consider revisiting this course soon!</strong>
        </span>
        <span class="text-green-600" v-else-if="course.isCompleted">
          <strong
            >Completed ({{
              $filters.formatDate(course.lastCompletionDate)
            }})</strong
          >
        </span>
        <span
          class="text-primary-blue"
          v-else-if="course.completedPercentage > 0 && !course.isCompleted"
        >
          <strong>In progress</strong>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import ProgressBar from "primevue/progressbar";
export default {
  components: {
    ProgressBar,
  },
  props: ["course"],
  data() {
    return {
      showMore: false,
      maxWords: 14,
    };
  },
  emits: ["action"],
  computed: {
    meta() {
      return this.course.shouldRetakeNow
        ? "Must revisit"
        : // : this.course.completedPercentage > 0 && !this.course.isCompleted
          // ? "In progress"
          "";
    },
    actionText() {
      return this.course.shouldRetakeNow
        ? "Revisit"
        : this.course.completedPercentage > 0 && !this.course.isCompleted
        ? "View"
        : "Start";
    },
    actionButtonColor() {
      // if (this.actionText !== "Revisit") return "btn-secondary";
      if (this.course.shouldRetakeNow) {
        return "bg-red-600";
      } else if (this.course.shouldRetakeSoon) {
        return "bg-yellow-600";
      } else if (this.course.isCompleted) {
        return "bg-green-600";
      } else if (
        this.course.completedPercentage > 0 &&
        !this.course.isCompleted
      ) {
        return "bg-primary-blue";
      } else return "btn-secondary";
      // } else return "bg-primary-blue";
    },
    progressBarColor() {
      if (this.course.shouldRetakeNow) {
        return "#DC2625";
      } else if (this.course.shouldRetakeSoon) {
        return "#CA8A03";
      } else if (this.course.isCompleted) {
        return "#17A34A";
      } else return "rgb(60, 120, 216)";
    },
    imageOrDefault() {
      return (
        this.course.imageUrl ??
        "https://media-exp1.licdn.com/dms/image/C561BAQEYonrtVbwOtA/company-background_10000/0/1519799746732?e=2159024400&v=beta&t=UWLOH5x-Ej0tRiAdObztvxnsi4t-2b5dkTbafuTGJ1g"
      );
    },
    truncatedDescription() {
      if (this.showMore) {
        return this.course.description;
      }
      const words = this.course.description.split(" ");
      return words.length > this.maxWords
        ? words.slice(0, this.maxWords).join(" ") + "..."
        : this.course.description;
    },
  },
  methods: {
    async onClickAction() {
      console.log("COURSE", this.course, this.course.id);
      this.$router.push({
        name: "course",
        params: { courseId: this.course.id },
      });
    },
    toggleShowMore() {
      this.showMore = !this.showMore;
    },
  },
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-img-top-rounded {
  width: 100%;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.status-badge {
  border-radius: 20px;
}
.badge {
  white-space: pre-wrap;
  text-align: left;
}

.card {
  border-radius: 16px;
  border: none;
  box-shadow: 0 1px 2.2px rgb(0 0 0 / 2%), 0 2.3px 5.3px rgb(0 0 0 / 3%),
    0 4.4px 10px rgb(0 0 0 / 4%), 0 7.8px 17.9px rgb(0 0 0 / 4%),
    0 14.6px 33.4px rgb(0 0 0 / 5%), 0 35px 80px rgb(0 0 0 / 7%);
}

h2.page-title {
  font-weight: 800;
  color: #fff;
}
h5.page-title {
  color: #fff;
}

.card-img-overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-title {
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000;
}

.btn.round {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

h5.copy-to-clipboard:hover {
  cursor: pointer;
  color: cornflowerblue;
}

.row {
  display: flex;
}

.left {
  flex: 1;
}

.right {
  width: 350px;
}
</style>