<template>
  <div style="display: flex; flex-direction: column; justify-content: center">
    <div class="dropdown">
      <a class="profile dropdown-toggle" href="#" id="dropdownMenuButton1" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <span class="">{{ user.name }}</span>
        <div style="margin-left: 8px; margin-right: 12px" class="vs-avatar-content vs-change-color-badge">
          <div class="vs-avatar">
            <img :src="user.picture" alt="" />
          </div>
        </div>
      </a>

      <div class="profile dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <div style="text-align: center">
          <h3 class="section-subheader">Hi {{ user.firstName }}</h3>
        </div>
        <div class="mt-2" style="display: flex; justify-content: center">
          <div class="large vs-avatar-content vs-change-color-badge">
            <div class="vs-avatar">
              <img :src="user.picture" alt="" />
            </div>
          </div>
        </div>
        <div class="mt-3" style="display: none; flex-direction: row; justify-content: space-around">
          <router-link to="settings">
            <div style="text-align: center">
              <i class="dropdown-icon fas fa-user-circle"></i>
              <p style="margin-bottom: 0px">Account</p>
            </div>
          </router-link>
          <div class="dropdown-button" style="text-align: center">
            <i class="dropdown-icon fas fa-question-circle"></i>
            <p style="margin-bottom: 0px">Support</p>
          </div>
        </div>
        <div class="mt-3">
          <button @click="logout" class="btn btn-outline-warning btn-block">Log out</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { authPlugin as $auth } from "@/auth";

export default {
  name: "NavItemProfile",
  props: ["user"],
  methods: {
    logout() {
      const returnUrl = window.location.origin;

      $auth.logout({
        returnTo: returnUrl
      });
    }
  }
};
</script>


<style scoped>
.profile.dropdown-toggle {
  display: flex;
  align-items: center;
  font-weight: 600;
  color: #F4E8D3;
}
.profile.dropdown-toggle::after {
  color: #F4E8D3;
}
/*
.profile.dropdown-toggle::after {
    display: none;
    margin: 0;
    padding: 0;
}
*/

.profile.dropdown-menu {
  left: -180px;
  padding: 30px 20px;
  width: 300px;

  border: none;
  border-radius: 15px;
  box-shadow: 0 1px 2.2px rgb(0 0 0 / 2%), 0 2.3px 5.3px rgb(0 0 0 / 3%), 0 4.4px 10px rgb(0 0 0 / 4%), 0 7.8px 17.9px rgb(0 0 0 / 4%), 0 14.6px 33.4px rgb(0 0 0 / 5%), 0 35px 80px rgb(0 0 0 / 7%);
}

.large.vs-avatar-content {
  width: 70px;
  height: 70px;
}

.dropdown-icon {
  font-size: 32px;
  color: #393c7f;
}
</style>
