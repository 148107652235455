import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { setupAuth } from './auth'

import * as apiGetActiveUserProfile from '@/api/me/getActiveUserProfile'

import Toast from "vue-toastification";

import VuePlyr from 'vue-plyr'


import VJsoneditor from 'v-jsoneditor/src/index'

import VueClipboard from 'vue3-clipboard'

import Vue3VideoPlayer from '@cloudgeek/vue3-video-player'


import vueVimeoPlayer from 'vue-vimeo-player'
import PrimeVue from 'primevue/config';
const app = createApp(App)
app.use(PrimeVue);


import ProgressSpinner from 'primevue/progressspinner';
app.component('ProgressSpinner', ProgressSpinner);
// Global loading
import Loading from './components/Loading.vue'
app.component('Loading', Loading)

import msalPlugin from './libs/vue-msal-browser'
const msalConfig = {
  auth: {
    tenant: 'rekomgroupb2c',
    clientId: process.env.VUE_APP_AD_B2C_CLIENT_ID,
    authority: process.env.VUE_APP_AD_B2C_AUTHORITY + process.env.VUE_APP_AD_B2C_POLICY_SIGNIN,
    knownAuthorities: [process.env.VUE_APP_AD_B2C_AUTHORITY + process.env.VUE_APP_AD_B2C_POLICY_SIGNIN],
    validateAuthority: false,
    redirectUri: process.env.VUE_APP_AD_B2C_REDIRECT_URI, // It has to be configured on your Azure tenant
    scopes: ['https://rekomgroupb2c.onmicrosoft.com/rekom-life-api/api']
  },
  cache: {
    cacheLocation: 'localStorage'
  },
  mode: "redirect"
}
app.use(msalPlugin, msalConfig);

app.use(router)
app.use(store)

app.use(Toast)

app.use(VuePlyr)

app.use(VJsoneditor)

app.use(VueClipboard, {
  autoSetContainer: true,
  appendToBody: true,
})

app.use(Vue3VideoPlayer)
app.use(vueVimeoPlayer)

// Global Components registration
// import Sidebar from './components/Sidebar'
// app.component('Sidebar', Sidebar)

import NavBar from './components/NavBar';
app.component('NavBar', NavBar)

import LeftMenuLayout from './layouts/LeftMenuLayout'
app.component('LeftMenuLayout', LeftMenuLayout)

import LeapForm from '@/leapform/LeapForm'

import './assets/tailwind.css'

import "vue-toastification/dist/index.css";
import 'vue-plyr/dist/vue-plyr.css'
import '@cloudgeek/vue3-video-player/dist/vue3-video-player.css'

import "./assets/styles/style.css";
import "./assets/styles/campusOnline-base.css";
import "./assets/styles/campusOnline-card.css";
import "./assets/styles/campusOnline-avatar.css";
import "./assets/styles/campusOnline-table.css";
//theme
import "@/assets/primevue-theme.css";
//core
import "primevue/resources/primevue.min.css";


import Tooltip from 'primevue/tooltip';
app.directive('tooltip', Tooltip);

app.component('LeapForm', LeapForm)

import filters from "./utils/filters";
app.config.globalProperties.$filters = filters;

function callbackRedirect(error) {
  let redirectUrl = localStorage.getItem('rekom.campusonline.redirectUrl');

  if (error) {
    console.log(error)
  } else {
    try {
      router.push({ path: redirectUrl ? redirectUrl : '/' })
    } catch (err) {
      console.log('Could not route:', err)
    }
  }
}

const redirectUrl = location.pathname + location.search
localStorage.setItem('rekom.campusonline.redirectUrl', redirectUrl);

function getAccessTokenFromURL() {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('accessToken');
}

const providedToken = getAccessTokenFromURL();


setupAuth(callbackRedirect, providedToken).then(async (authPlugin) => {
  if (authPlugin) {
    const activeUserProfile = await apiGetActiveUserProfile.get()
    console.log('GOT ACTIVE USER:', activeUserProfile)

    console.log('Mounting app...')
    app.use(authPlugin).mount('#app')
  }
}, error => {
  console.error('Error after password reset', error);
  localStorage.clear();
  window.location.reload();
});


/*
(async (authPlugin) => {
    if (authPlugin) {
        const activeUserProfile = await apiGetActiveUserProfile.get()
        console.log('GOT ACTIVE USER:', activeUserProfile)

        console.log('Mounting app...')
        app.use(authPlugin).mount('#app')
    }
})*/